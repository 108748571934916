@import "../../_globalColor";

.meter {
  height: 20px;
  position: relative;
  background: $progressBarBackground;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: $progressBarSpanBG;
  position: relative;
  overflow: hidden;
}
.skill {
  line-height: 3.5vw;
}
.skill-bars {
  font-size: 28px;
  width: 40%;
}
.skills-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}
.skills-container > * {
  flex: 1;
  margin-bottom: 30px;
}
.skills-image > img {
  margin-left: 80px;
  max-width: 90%;
  height: auto;
}

/* Media Query */

@media (max-width: 1456px) {
  .skills-bar {
    line-height: 4rem;
  }
  .skills-image {
    display: none;
    order: 2;
  }
}

.tagcloud-container {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 650;
  margin-left: 0px;
  color: #868e96;
}

.prof-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prof-text {
  line-height: 50px;
  padding-right: 7em;
  white-space: pre-line;
  color: #868e96;
}

.prof-text-dark {
  line-height: 50px;
  padding-right: 7em;
  white-space: pre-line;
  color: white;
}

.quote {
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  padding: 1rem 1.2rem;
  width: 80%; /* create space for the quotes */
  color: #868e96;
  margin: 1rem auto;
}

.quote-dark {
  text-align: center;
  font-family: "Montserrat";
  font-size: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  padding: 1rem 1.2rem;
  width: 80%; /* create space for the quotes */
  color: white;
  margin: 1rem auto;
}

blockquote.quote-dark:before,
blockquote.quote-dark:after {
  font-family: FontAwesome;
  position: absolute;
  color: #039be5;
  font-size: 34px;
}

blockquote.quote:before,
blockquote.quote:after {
  font-family: FontAwesome;
  position: absolute;
  color: #039be5;
  font-size: 34px;
}

blockquote.EN {
  background: linear-gradient(to right, #039be5 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #039be5 4px, transparent 4px) 100% 0,
    linear-gradient(to bottom, #039be5 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #039be5 4px, transparent 4px) 0 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

blockquote.EN:before {
  content: "\f10d";
  top: -12px;
  margin-right: -20px;
  right: 100%;
}

blockquote.EN:after {
  content: "\f10e";
  margin-left: -20px;
  left: 100%;
  top: auto;
  bottom: -20px;
}

.tag-cloud {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 1250px) {
  .prof-container {
    flex-direction: column;
  }
  .prof-text {
    display: flex;
    line-height: 40px;
    padding-right: 0em;
    justify-content: center;
  }
  .prof-text-dark {
    display: flex;
    line-height: 40px;
    padding-right: 0em;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column;
  }
  .skills-bar {
    line-height: 3rem;
    margin: 20px;
  }
  .skills-image {
    display: none;
    order: 2;
  }
  .legend {
    display: none;
    background-color: red;
  }
  .tagcloud-container {
    font-size: 10px;
    margin-left: 0px;
  }
  .quote {
    font-size: 18px;
  }
}
